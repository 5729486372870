import { useState, useEffect } from "react";
import { collection, query, onSnapshot, doc, getDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import FocusCards from "../components/ui/focus-cards";
import { Element } from "react-scroll";
import Button from "../components/Button";
import AddProjectForm from "../components/AddProjectForm";
import Popup from "../components/Popup";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      setIsAuthenticated(!!user);
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists() && userDoc.data().role === "admin") {
          setIsAdmin(true);
        }
      } else {
        setIsAdmin(false);
      }
    });

    const q = query(collection(db, "projects"));
    const unsubscribeProjects = onSnapshot(q, (querySnapshot) => {
      const projectsArray = [];
      querySnapshot.forEach((doc) => {
        projectsArray.push({ id: doc.id, ...doc.data() });
      });
      setProjects(projectsArray);
    });

    return () => {
      unsubscribeAuth();
      unsubscribeProjects();
    };
  }, []);

  const handleAddProject = () => {
    setShowAddForm(true);
  };

  const handleProjectAdded = () => {
    setShowAddForm(false);
  };

  const handleProjectDeleted = (deletedId) => {
    // La eliminación se maneja automáticamente por el listener de Firestore
  };

  const handleProjectEdited = (updatedProject) => {
    // La edición se maneja automáticamente por el listener de Firestore
  };

  return (
    <section id="projects">
      <Element name="projects" className="relative pb-32 pt-12 max-lg:pb-24 max-md:py-8 max-md:pt-[250px]">
        <div className="container">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-4xl font-bold text-center">PROJECTS</h2>
            {isAdmin && (
              <Button onClick={handleAddProject} className="bg-p1 text-s1 px-4 py-2 rounded-md">
                Add Project
              </Button>
            )}
          </div>
          <div className="flex items-center">
            <div className="download_preview-before download_preview-after rounded-40 relative w-full border-2 border-s5 p-6">
              <div className="relative rounded-3xl bg-s1 px-6 pb-6 pt-14">
                <span className="download_preview-dot left-6 bg-p2" />
                <span className="download_preview-dot left-11 bg-s3" />
                <span className="download_preview-dot left-16 bg-p1/15" />
                <FocusCards 
                  cards={projects} 
                  onProjectDeleted={handleProjectDeleted}
                  onProjectEdited={handleProjectEdited}
                />
              </div>
            </div>
          </div>
        </div>
      </Element>
      {showAddForm && (
        <Popup onClose={() => setShowAddForm(false)}>
          <AddProjectForm onProjectAdded={handleProjectAdded} />
        </Popup>
      )}
    </section>
  );
}

export default Projects;
