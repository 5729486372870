import React from 'react';
import { motion } from 'framer-motion';

const ComingSoonCard = () => {
  return (
    <div className="relative h-[300px] overflow-hidden rounded-xl border border-s3 bg-black/70 flex items-center justify-center">
      <motion.h3 
        className="text-2xl font-bold text-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Coming soon
        <motion.span
          className="inline-block"
          animate={{ opacity: [0, 1, 0] }}
          transition={{ duration: 1.5, repeat: Infinity, ease: "linear" }}
        >
          ...
        </motion.span>
      </motion.h3>
    </div>
  );
};

export default ComingSoonCard;
