import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/Button';

const NotFound = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-s1 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 text-center">
        <h1 className="text-6xl font-bold text-p4">404</h1>
        <h2 className="mt-6 text-3xl font-extrabold text-p4">Page Not Found</h2>
        <p className="mt-2 text-sm text-p4">The page you're looking for doesn't exist or has been moved.</p>
        <div className="mt-6">
          <Link to="/">
            <Button className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-s1 bg-p1 hover:bg-p1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-p1">
              Go back to home
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
