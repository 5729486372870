import { Element, Link as LinkScroll } from "react-scroll";
import Button from "../components/Button.jsx";


const Hero = () => {
  return (
    <section className="relative pt-80 pb-40 max-lg:pt-80 max-lg:pb-20 max-md:pt-52 max-md:pb-72">
      <Element name="hero">
        <div className="container">
          <div className="relative z-2 max-w-512 max-lg:max-w-388">
            <div className="caption small-2 uppercase text-p1">
                Technological services
            </div>
            <h1 className="mb-6 h1 text-p4 uppercase max-lg:mb-7 max-lg:h2 max-md:mb-4 max-md:text-5xl max-md:leading-12">
              Facing Future
            </h1>
            <p className="max-w-440 mb-14 body-1 max-md:mb-10">
            We provide cutting-edge tech solutions to keep your business ahead in a rapidly evolving digital landscape.
            </p>
            <LinkScroll to="download" offset={-100} spy smooth>
              <Button icon="/images/zap.svg">Consult Us</Button>
            </LinkScroll>
          </div>
          <video
              autoPlay
              muted
              loop
              className="md:rotate-180 absolute top-[385px] sm:top-[450px] md:top-[-420px] lg:top-[-445px] h-full w-full left-[0.3%] object-cover"
            >
              <source src="/images/blackhole.webm" type="video/webm" />
            </video>
          <div className="absolute w-full pointer-events-none hero-img_res 
                          -top-16 md:-top-16 sm:-top-24
                          left-0 md:left-[calc(50%-290px)] max-md:hidden">
            <img
              src="/images/hero.png"
              className=" w-full md:w-[1230px] max-w-none md:max-w-full h-auto"
              alt="hero"
            />
          </div>
        </div>
      </Element>
    </section>
  );
};

export default Hero;
