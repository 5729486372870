import { Element } from "react-scroll";
import { teamMembers } from "../constants/index";
import { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";


const Team = () => {
  const [hoveredMembers, setHoveredMembers] = useState({});
  const memberRefs = useRef([]);

  useEffect(() => {
    const handleMouseEnter = (index) => {
      setHoveredMembers(prev => ({ ...prev, [index]: true }));
    };

    const handleMouseLeave = (index) => {
      setHoveredMembers(prev => ({ ...prev, [index]: false }));
    };

    memberRefs.current.forEach((ref, index) => {
      if (ref) {
        ref.addEventListener('mouseenter', () => handleMouseEnter(index));
        ref.addEventListener('mouseleave', () => handleMouseLeave(index));
      }
    });

    return () => {
      memberRefs.current.forEach((ref, index) => {
        if (ref) {
          ref.removeEventListener('mouseenter', () => handleMouseEnter(index));
          ref.removeEventListener('mouseleave', () => handleMouseLeave(index));
        }
      });
    };
  }, []);

  const handleMemberClick = (linkedIn) => {
    window.open(linkedIn, '_blank', 'noopener,noreferrer');
  };

  const GlowingText = ({ text }) => {
    const words = text.split(' ');
    return (
      <p className="text-center text-white">
        {words.map((word, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0.5 }}
            animate={{ opacity: [0.5, 1, 0.5] }}
            transition={{
              duration: 4,  // Aumentado de 2 a 4 segundos
              repeat: Infinity,
              delay: index * 0.5,  // Aumentado de 0.1 a 0.5 segundos
              ease: "easeInOut"  // Añadido para una transición más suave
            }}
            className="inline-block mr-1"
          >
            {word}
          </motion.span>
        ))}
      </p>
    );
  };

  return (
    <section className="relative py-24 g7">
      <Element name="team">
        <div className="container">
          <h2 className="text-center mb-16 h2 text-p4 uppercase">Team Members</h2>
          <div className="flex flex-wrap justify-center gap-16">
            {teamMembers.map((member, index) => (
              <div 
                key={member.id} 
                className="flex flex-col items-center max-w-sm cursor-pointer"
                onClick={() => handleMemberClick(member.linkedIn)}
                ref={el => memberRefs.current[index] = el}
              >
                <motion.div 
                  className="relative w-64 h-64 mb-6"
                  animate={{ rotateY: hoveredMembers[index] ? 180 : 0 }}
                  transition={{ duration: 0.4 }}
                  style={{ perspective: 1000 }}
                >
                  <motion.div
                    className="absolute w-full h-full rounded-full border-4 border-p1 overflow-hidden backface-hidden"
                    animate={{ rotateY: hoveredMembers[index] ? 180 : 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <img
                      src={member.image}
                      alt={member.name}
                      className="w-full h-full object-cover"
                    />
                  </motion.div>
                  <motion.div
                    className="absolute w-full h-full rounded-full border-4 border-p1 overflow-hidden backface-hidden"
                    initial={{ rotateY: 180 }}
                    animate={{ rotateY: hoveredMembers[index] ? 0 : 180 }}
                    transition={{ duration: 0.4 }}
                  >
                    <div className="w-full h-full bg-gradient-to-r from-p1 via-p2 to-purple-700 animate-gradient-x">
                      <div className="absolute inset-0 bg-black opacity-50"></div>
                      <AnimatePresence>
                        {hoveredMembers[index] && (
                          <motion.div
                            className="w-full h-full flex items-center justify-center relative z-10"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            style={{ transform: 'rotateY(180deg)' }}
                          >
                            <motion.p 
                              className="text-center font-bold text-white px-4"
                            >
                              {member.role}
                            </motion.p>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  </motion.div>
                </motion.div>
                <h3 className="text-2xl font-bold text-p3 mb-2">{member.name}</h3>
                <GlowingText text={member.description} />
              </div>
            ))}
          </div>
        </div>
      </Element>
    </section>
  );
};

export default Team;
