import React from 'react';
import { motion } from 'framer-motion';

const LoadingScreen = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div className="relative w-[400px] h-[400px]">
        <motion.img
          src="/images/FacingFuture.svg"
          alt="Facing Future Logo"
          className="w-full h-full object-contain z-10 relative"
          animate={{
            scale: [0.8, 1.2, 0.8],
            opacity: [0.3, 1, 0.3]
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <img
          src="/images/FacingFuture.svg"
          alt="Facing Future Logo Background"
          className="absolute top-0 left-0 w-full h-full object-contain opacity-10 filter blur-2xl"
        />
      </div>
    </div>
  );
};

export default LoadingScreen;
