import React from 'react';

const Popup = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-s1 p-6 rounded-lg shadow-xl max-w-md w-full">
        <button onClick={onClose} className="float-right text-p1 hover:text-p1/80">
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Popup;
