import { Link } from "react-scroll";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { auth } from '../firebase';

const Footer = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/');
    }).catch((error) => {
      console.error("Error al cerrar sesión:", error);
    });
  };

  return (
    <footer className="bg-s1 py-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-6 md:mb-0">
            <img src="/images/FacingFuture.svg" alt="Facing Future Logo" className="h-16" />
          </div>
          <nav className="mb-6 md:mb-0">
            <ul className="flex space-x-6">
              <li><Link to="root" spy={true} smooth={true} offset={-100} duration={500} className="text-p5 hover:text-p1 transition-colors cursor-pointer">Home</Link></li>
              <li><Link to="projects" spy={true} smooth={true} offset={-100} duration={500} className="text-p5 hover:text-p1 transition-colors cursor-pointer">Projects</Link></li>
              <li><Link to="features" spy={true} smooth={true} offset={-100} duration={500} className="text-p5 hover:text-p1 transition-colors cursor-pointer">Features</Link></li>
              <li><Link to="faq" spy={true} smooth={true} offset={-100} duration={500} className="text-p5 hover:text-p1 transition-colors cursor-pointer">FAQ</Link></li>
            </ul>
          </nav>
          <div className="text-center md:text-right">
            <p className="text-p5">&copy; 2024 Facing Future. All rights reserved.</p>
            <div className="mt-2">
              <a href="#" className="text-p5 hover:text-p1 transition-colors cursor-pointer mr-4">Privacy Policy</a>
              <a href="#" className="text-p5 hover:text-p1 transition-colors cursor-pointer">Terms of Use</a>
            </div>
            {isAuthenticated && (
              <button
                onClick={handleLogout}
                className="mt-4 text-p5 hover:text-p1 transition-colors cursor-pointer"
              >
                Cerrar sesión
              </button>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
