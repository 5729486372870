import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase";
import Button from "./Button";

const EditProjectForm = ({ project, onClose, onProjectEdited }) => {
  const [title, setTitle] = useState(project.title);
  const [description, setDescription] = useState(project.description);
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState(project.url);
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let imageUrl = project.image;
      if (image) {
        const storageRef = ref(storage, `projects/${image.name}`);
        const snapshot = await uploadBytes(storageRef, image);
        imageUrl = await getDownloadURL(snapshot.ref);
      }

      const projectRef = doc(db, "projects", project.id);
      await updateDoc(projectRef, {
        title,
        description,
        image: imageUrl,
        url,
        updatedAt: new Date()
      });

      const updatedProject = {
        ...project,
        title,
        description,
        image: imageUrl,
        url
      };
      onProjectEdited(updatedProject);
      onClose();
    } catch (error) {
      console.error("Error editing project:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-s1 p-6 rounded-lg shadow-xl max-w-md w-full">
        <h3 className="text-2xl font-bold text-p1 mb-4">Editar Proyecto</h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Título"
            className="w-full p-2 border rounded"
            required
          />
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Descripción"
            className="w-full p-2 border rounded"
            required
          />
          <input
            type="file"
            onChange={handleImageChange}
            className="w-full p-2 border rounded"
          />
          <input
            type="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="URL del proyecto"
            className="w-full p-2 border rounded"
            required
          />
          <div className="flex justify-end space-x-4">
            <Button onClick={onClose} className="bg-s3 text-p4 px-4 py-2 rounded">
              Cancelar
            </Button>
            <Button type="submit" className="bg-p1 text-s1 px-4 py-2 rounded">
              Guardar Cambios
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditProjectForm;
