import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth, db } from "../firebase";
import Button from './Button';
import { setDoc, doc } from "firebase/firestore";

function Form({ method }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const navigate = useNavigate();

    const name = method === "login" ? "Iniciar Sesión" : "Registrarse";

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (method === "register" && password !== confirmPassword) {
            alert("Las contraseñas no coinciden.");
            setLoading(false);
            return;
        }

        try {
            if (method === "login") {
                await signInWithEmailAndPassword(auth, email, password);
            } else {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                const user = userCredential.user;

                // Enviar correo de verificación
                await sendEmailVerification(user);

                await setDoc(doc(db, "users", user.uid), {
                    email: user.email,
                    firstName,
                    lastName,
                    phoneNumber,
                    role: "user"
                });

                alert("Se ha enviado un correo de verificación. Por favor, verifica tu correo antes de iniciar sesión.");
            }
            navigate("/");
        } catch (error) {
            console.error("Error:", error);
            alert(`Error: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-s1 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <img className="mx-auto h-12 w-auto" src="/images/FacingFuture.svg" alt="Facing Future" />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-p4">{name}</h2>
                </div>
                <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    <input type="hidden" name="remember" value="true" />
                    <div className="rounded-md shadow-sm -space-y-px">
                        {method === "register" && (
                            <>
                                <div>
                                    <input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-s4 placeholder-s4 text-p4 rounded-t-md focus:outline-none focus:ring-p1 focus:border-p1 focus:z-10 sm:text-sm bg-s2"
                                        placeholder="Nombre"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <input
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-s4 placeholder-s4 text-p4 focus:outline-none focus:ring-p1 focus:border-p1 focus:z-10 sm:text-sm bg-s2"
                                        placeholder="Apellido"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <input
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        type="tel"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-s4 placeholder-s4 text-p4 focus:outline-none focus:ring-p1 focus:border-p1 focus:z-10 sm:text-sm bg-s2"
                                        placeholder="Número de teléfono"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                </div>
                            </>
                        )}
                        <div>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-s4 placeholder-s4 text-p4 focus:outline-none focus:ring-p1 focus:border-p1 focus:z-10 sm:text-sm bg-s2"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="relative">
                            <input
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-s4 placeholder-s4 text-p4 focus:outline-none focus:ring-p1 focus:border-p1 focus:z-10 sm:text-sm bg-s2"
                                placeholder="Contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
  
                        </div>
                        {method === "register" && (
                            <div>
                                <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type={showPassword ? "text" : "password"}
                                    required
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-s4 placeholder-s4 text-p4 focus:outline-none focus:ring-p1 focus:border-p1 focus:z-10 sm:text-sm bg-s2"
                                    placeholder="Repetir Contraseña"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <Button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-s1 bg-p1 hover:bg-p1/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-p1">
                            {loading ? "Cargando..." : name}
                        </Button>
                    </div>
                </form>
                <p className="mt-2 text-center text-sm text-p4">
                    {method === "login" ? "¿No tienes una cuenta?" : "¿Ya tienes una cuenta?"}{' '}
                    <Link to={method === "login" ? "/register" : "/login"} className="font-medium text-p1 hover:text-p1/80">
                        {method === "login" ? "Regístrate" : "Inicia sesión"}
                    </Link>
                </p>
            </div>
        </div>
    );
}

export default Form;
