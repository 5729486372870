"use client";

import React, { useState, useEffect } from "react";
import { cn } from "../../lib/cn";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChrome } from '@fortawesome/free-brands-svg-icons';
import { faSquareMinus, faEdit  } from '@fortawesome/free-regular-svg-icons';
import { db, auth } from "../../firebase";
import { doc, deleteDoc, getDoc } from "firebase/firestore";
import { createPortal } from 'react-dom';
import EditProjectForm from '../EditProjectForm';
import ComingSoonCard from '../ComingSoonCard';

const useClientPortal = (children) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted ? createPortal(children, document.body) : null;
};

const FocusCards = ({ cards, onProjectDeleted, onProjectEdited }) => {
  const [hovered, setHovered] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(null);
  const [editingProject, setEditingProject] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      setIsAuthenticated(!!user);
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        setIsAdmin(userDoc.exists() && userDoc.data().role === "admin");
      } else {
        setIsAdmin(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "projects", id));
      onProjectDeleted(id);
      setShowConfirmation(null);
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  const handleEdit = (project) => {
    setEditingProject(project);
  };

  const popup = showConfirmation ? (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-s1 p-6 rounded-lg shadow-xl max-w-md w-full">
        <h3 className="text-2xl font-bold text-p1 mb-4">Confirmar eliminación</h3>
        <p className="text-p4 mb-6">¿Estás seguro de que quieres eliminar este proyecto?</p>
        <div className="flex justify-end space-x-4">
          <button
            className="bg-s3 text-p4 px-4 py-2 rounded hover:bg-s3/80 transition-colors"
            onClick={() => setShowConfirmation(null)}
          >
            Cancelar
          </button>
          <button
            className="bg-p1 text-s1 px-4 py-2 rounded hover:bg-p1/80 transition-colors"
            onClick={() => handleDelete(showConfirmation)}
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  ) : null;

  const editForm = editingProject ? (
    <EditProjectForm
      project={editingProject}
      onClose={() => setEditingProject(null)}
      onProjectEdited={(updatedProject) => {
        onProjectEdited(updatedProject);
        setEditingProject(null);
      }}
    />
  ) : null;

  const totalCards = 6;
  const projectCards = cards.slice(0, totalCards);
  const comingSoonCards = Array(Math.max(0, totalCards - projectCards.length)).fill(null);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {projectCards.map((card, index) => (
          <div
            key={card.id}
            className="relative h-[300px] overflow-hidden rounded-xl border border-s3"
            onMouseEnter={() => setHovered(index)}
            onMouseLeave={() => setHovered(null)}
          >
            <img
              src={`${card.image}`}
              alt={card.title}
              className="absolute inset-0 h-full w-full object-cover"
            />
            {isAdmin && (
              <div className="absolute top-2 right-2 flex space-x-2 z-10">
                <button
                  className="text-p1 rounded-full p-1 hover:text-p1/80 transition-colors"
                  onClick={() => handleEdit(card)}
                >
                  <FontAwesomeIcon icon={faEdit} size="lg" />
                </button>
                <button
                  className="text-p1 rounded-full p-1 hover:text-p1/80 transition-colors"
                  onClick={() => setShowConfirmation(card.id)}
                >
                  <FontAwesomeIcon icon={faSquareMinus} size="lg" />
                </button>
              </div>
            )}
            <div className={cn(
              "absolute inset-0 bg-black/70 flex flex-col justify-between p-6 transition-opacity duration-300 overflow-y-auto scrollbar-hide-completely",
              hovered === index ? "opacity-100" : "opacity-0"
            )}>
              <div>
                <h3 className="text-2xl font-bold text-white mb-2">{card.title}</h3>
                <p className="text-base text-white">{card.description}</p>
              </div>
            </div>
            <a 
              href={card.url} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="absolute bottom-2 right-2 flex justify-center items-center w-8 h-8 bg-white/20 text-white/70 rounded-full hover:bg-white/30 hover:text-white transition-colors duration-300 z-20"
            >
              <FontAwesomeIcon icon={faChrome} />
            </a>
          </div>
        ))}
        {comingSoonCards.map((_, index) => (
          <ComingSoonCard key={`coming-soon-${index}`} />
        ))}
      </div>
      {useClientPortal(popup)}
      {useClientPortal(editForm)}
    </>
  );
};

export default FocusCards;
