import { useState, useEffect, useRef } from 'react';
import { getClient } from "@botpress/webchat";

const Chatbot = () => {
  const [client, setClient] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isWriting, setIsWriting] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const initBotpress = async () => {
      const clientId = "98f47b02-63d2-4200-bf15-edd3bccc49a7";
      const botpressClient = getClient({ clientId });
      
      try {
        await botpressClient.connect();
        
        botpressClient.on("message", (message) => {
          if (message.payload && message.payload.type === 'bubble') {
            const botMessage = message.payload.block.text;
            setMessages(prevMessages => [...prevMessages, { text: botMessage, isBot: true }]);
            setIsWriting(false);
          }
        });

        setClient(botpressClient);
      } catch (error) {
        console.error("Error al conectar con Botpress:", error);
      }
    };

    initBotpress();
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, isWriting]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.trim() === '') return;

    setMessages(prevMessages => [...prevMessages, { text: input, isBot: false }]);
    setInput('');
    setIsWriting(true);

    if (client) {
      try {
        await client.sendMessage(input);
      } catch (error) {
        console.error("Error al enviar mensaje al bot:", error);
        setMessages(prevMessages => [...prevMessages, { text: "Lo siento, hubo un error al procesar tu mensaje.", isBot: true }]);
        setIsWriting(false);
      }
    } else {
      console.error("El cliente de Botpress no está inicializado");
      setMessages(prevMessages => [...prevMessages, { text: "Lo siento, el chat no está listo aún. Por favor, intenta de nuevo en unos momentos.", isBot: true }]);
      setIsWriting(false);
    }
  };

  return (
    <div className="bg-s1 border-2 border-s3 rounded-3xl p-4 shadow-lg w-full max-w-3xl mx-auto">
      <div className="bg-s2 rounded-2xl overflow-hidden flex flex-col h-[600px]">
        <div className="bg-p1 text-s1 p-3 font-bold text-lg">Facing Future ChatBot</div>
        <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4 space-y-4 scrollbar-hide-completely">
          {messages.map((message, index) => (
            <div key={index} className={`flex ${message.isBot ? 'justify-start' : 'justify-end'}`}>
              <div className={`rounded-lg p-2 max-w-[75%] ${message.isBot ? 'bg-s3 text-p4' : 'bg-p1 text-s1'}`}>
                {message.text}
              </div>
            </div>
          ))}
          {isWriting && (
            <div className="flex justify-start">
              <div className="bg-s3 text-p4 rounded-lg p-2 max-w-[75%]">
                Writing
                <span className="typing-animation">
                  <span>.</span><span>.</span><span>.</span>
                </span>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        <form onSubmit={handleSubmit} className="p-3 bg-s2 border-t border-s3">
          <div className="flex">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="flex-1 bg-s3 text-p4 rounded-l-lg p-2 outline-none"
              placeholder="Escribe un mensaje..."
            />
            <button type="submit" className="bg-p1 text-s1 rounded-r-lg px-4 py-2 font-semibold">Enviar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Chatbot;