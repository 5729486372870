
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { applyActionCode } from 'firebase/auth';

const EmailVerification = () => {
  const [verificationStatus, setVerificationStatus] = useState('Verificando...');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const oobCode = urlParams.get('oobCode');

      if (oobCode) {
        try {
          await applyActionCode(auth, oobCode);
          setVerificationStatus('Email verificado con éxito. Redirigiendo...');
          setTimeout(() => navigate('/login'), 3000);
        } catch (error) {
          console.error('Error al verificar el email:', error);
          setVerificationStatus('Error al verificar el email. Por favor, intenta de nuevo.');
        }
      } else {
        setVerificationStatus('Código de verificación no encontrado.');
      }
    };

    verifyEmail();
  }, [navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-s1">
      <div className="bg-s2 p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-p1">Verificación de Email</h2>
        <p className="text-p4">{verificationStatus}</p>
      </div>
    </div>
  );
};

export default EmailVerification;