import { Element } from "react-scroll";
import { links, logos } from "../constants/index.jsx";
import { Marker } from "../components/Marker.jsx";
import Chatbot from "../components/Chatbot.jsx";

const Download = () => {
  const handleSocialClick = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <section>
      <Element
        name="download"
        className="g7 relative pb-32 pt-24 max-lg:pb-24 max-md:py-16"
      >
        <div className="container">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="relative mr-6 flex-540 max-xl:flex-280 max-lg:flex256 max-md:flex-100 mb-8 md:mb-0">
              <div className="mb-10">
                <img
                  src="/images/FacingFuture.svg"
                  width={550}
                  height={110}
                  alt="FF"
                />
              </div>

              <p className="body-1 mb-10 max-w-md">
              If you have any questions or concerns, please feel free to ask the chatbot. It is here to help you with anything you need, whether it is about our services, technical information, support or any other query. Or contact us directly.
              </p>

              <ul className="flex flex-wrap items-center gap-6">
                {links.map(({ id, url, icon, title }) => (
                  <li
                    key={id}
                    className="download_tech-link download_tech-link_last-before download_tech-link_last-after"
                  >
                    <button
                      onClick={() => handleSocialClick(url)}
                      className="size-22 download_tech-icon_before relative flex items-center justify-center rounded-half border-2 border-s3 bg-s1 transition-all duration-300"
                      aria-label={title}
                    >
                      <span className="absolute -top-2 rotate-90">
                        <Marker />
                      </span>
                      <img
                        src={"/images/lines.svg"}
                        alt="lines"
                        className="absolute size-13/20 object-contain"
                      />
                      <span className="download_tech-icon">{icon}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="w-full md:w-1/2">
              <Chatbot />
            </div>
          </div>

          {/* Logos temporalmente ocultos
          <ul className="mt-24 flex justify-center max-lg:hidden">
            {logos.map(({ id, url, width, height, title }) => (
              <li key={id} className="mx-10">
                <img src={url} width={width} height={height} alt={title} />
              </li>
            ))}
          </ul>
          */}
        </div>
      </Element>
    </section>
  );
};

export default Download;
